//import SMPNum from './SMPNum.js'
let SMPNum = require("../src/SMPNum");

const K_GAME_INTRO = 0;
const K_GAME_TUNNEL_1 = 1;
const K_GAME_TUNNEL_2 = 2;
const K_GAME_TUNNEL_3 = 3;
const K_GAME_DEFAULT = 4;

class Gameplay {

    constructor(ratios) {

        //////console.log('GAMEPLAY - RELOAD');
        this.dropCoinsRatios = [];
        this.dropCoinsRatios[K_GAME_INTRO] = 1.1;
        this.dropCoinsRatios[K_GAME_TUNNEL_1] = 1.2;
        this.dropCoinsRatios[K_GAME_TUNNEL_2] = 1.15;
        this.dropCoinsRatios[K_GAME_TUNNEL_3] = 1.15;
        this.dropCoinsRatios[K_GAME_DEFAULT] = 1.1;


        this.baseDmgRatios = [];
        this.baseDmgRatios[K_GAME_INTRO] = 1.1;
        this.baseDmgRatios[K_GAME_TUNNEL_1] = 1.1;
        this.baseDmgRatios[K_GAME_TUNNEL_2] = 1.019;
        this.baseDmgRatios[K_GAME_TUNNEL_3] = 1.0195;
        this.baseDmgRatios[K_GAME_DEFAULT] = 1.0195;

        this.supportDmgRatios = []; // no need to initialize the value now.

        this.baseHPGhostRatios = [];
        this.baseHPGhostRatios[K_GAME_INTRO] = 1.1;
        this.baseHPGhostRatios[K_GAME_TUNNEL_1] = 1.1;
        this.baseHPGhostRatios[K_GAME_TUNNEL_2] = 1.035;
        this.baseHPGhostRatios[K_GAME_TUNNEL_3] = 1.037;
        this.baseHPGhostRatios[K_GAME_DEFAULT] = 1.02;

        this.baseHPBossRatios = [];
        this.baseHPBossRatios[K_GAME_INTRO] = 1.1;
        this.baseHPBossRatios[K_GAME_TUNNEL_1] = 1.1;
        this.baseHPBossRatios[K_GAME_TUNNEL_2] = 1.035;
        this.baseHPBossRatios[K_GAME_TUNNEL_3] = 1.037;
        this.baseHPBossRatios[K_GAME_DEFAULT] = 1.02;



        this.costHeroLevelUpRatios = [];
        this.costHeroLevelUpRatios[K_GAME_INTRO] = 1.1;
        this.costHeroLevelUpRatios[K_GAME_TUNNEL_1] = 1.25;
        this.costHeroLevelUpRatios[K_GAME_TUNNEL_2] = 1.327;
        this.costHeroLevelUpRatios[K_GAME_TUNNEL_3] = 1.327;
        this.costHeroLevelUpRatios[K_GAME_DEFAULT] = 1.25;

        this.costSupportLevelUpRatios = [];
        this.costSupportLevelUpRatios[K_GAME_INTRO] = 1.1;
        this.costSupportLevelUpRatios[K_GAME_TUNNEL_1] = 1.25;
        this.costSupportLevelUpRatios[K_GAME_TUNNEL_2] = 1.327;
        this.costSupportLevelUpRatios[K_GAME_TUNNEL_3] = 1.327;
        this.costSupportLevelUpRatios[K_GAME_DEFAULT] = 1.25;

        if (ratios) {
            if (ratios.dropCoinsRatio) {
                //////console.log('GAMEPLAY - OVERRIDE RATIO DROPCOINS');
                this.dropCoinsRatios[K_GAME_INTRO] = Number.parseFloat(ratios.dropCoinsRatio.dropCoinsGameIntro);
                this.dropCoinsRatios[K_GAME_TUNNEL_1] = Number.parseFloat(ratios.dropCoinsRatio.dropCoinsTunnel1);
                this.dropCoinsRatios[K_GAME_TUNNEL_2] = Number.parseFloat(ratios.dropCoinsRatio.dropCoinsTunnel2);
                this.dropCoinsRatios[K_GAME_TUNNEL_3] = Number.parseFloat(ratios.dropCoinsRatio.dropCoinsTunnel3);
                this.dropCoinsRatios[K_GAME_DEFAULT] = Number.parseFloat(ratios.dropCoinsRatio.dropCoinsDefault);
            }

            //if (ratios.baseDmgRatios) {
                this.baseDmgRatios[K_GAME_INTRO] = Number.parseFloat(ratios.baseDmgRatio.baseDmgIntro);
                this.baseDmgRatios[K_GAME_TUNNEL_1] = Number.parseFloat(ratios.baseDmgRatio.baseDmgTunnel1);
                this.baseDmgRatios[K_GAME_TUNNEL_2] = Number.parseFloat(ratios.baseDmgRatio.baseDmgTunnel2);
                this.baseDmgRatios[K_GAME_TUNNEL_3] = Number.parseFloat(ratios.baseDmgRatio.baseDmgTunnel3);
                this.baseDmgRatios[K_GAME_DEFAULT] = Number.parseFloat(ratios.baseDmgRatio.baseDmgDefault);
            //}
            this.supportDmgRatios[K_GAME_INTRO] = Number.parseFloat(ratios.supportDmgRatio.supportBaseDmgIntro);
            this.supportDmgRatios[K_GAME_TUNNEL_1] = Number.parseFloat(ratios.supportDmgRatio.supportBaseDmgTunnel1);
            this.supportDmgRatios[K_GAME_TUNNEL_2] = Number.parseFloat(ratios.supportDmgRatio.supportBaseDmgTunnel2);
            this.supportDmgRatios[K_GAME_TUNNEL_3] = Number.parseFloat(ratios.supportDmgRatio.supportBaseDmgTunnel3);
            this.supportDmgRatios[K_GAME_DEFAULT] = Number.parseFloat(ratios.supportDmgRatio.supportBaseDmgDefault);
            //if (ratios.baseHPGhostRatios) {
                this.baseHPGhostRatios[K_GAME_INTRO] = Number.parseFloat(ratios.baseHPGhostRatio.baseHPGhostIntro);
                this.baseHPGhostRatios[K_GAME_TUNNEL_1] = Number.parseFloat(ratios.baseHPGhostRatio.baseHPGhostTunnel1);
                this.baseHPGhostRatios[K_GAME_TUNNEL_2] = Number.parseFloat(ratios.baseHPGhostRatio.baseHPGhostTunnel2);
                this.baseHPGhostRatios[K_GAME_TUNNEL_3] = Number.parseFloat(ratios.baseHPGhostRatio.baseHPGhostTunnel3);
                this.baseHPGhostRatios[K_GAME_DEFAULT] = Number.parseFloat(ratios.baseHPGhostRatio.baseHPGhostDefault);


            this.costHeroLevelUpRatios[K_GAME_INTRO] = Number.parseFloat(ratios.costHeroLevelUpRatio.costHeroLevelUpIntro);
            this.costHeroLevelUpRatios[K_GAME_TUNNEL_1] = Number.parseFloat(ratios.costHeroLevelUpRatio.costHeroLevelUpTunnel1);
            this.costHeroLevelUpRatios[K_GAME_TUNNEL_2] = Number.parseFloat(ratios.costHeroLevelUpRatio.costHeroLevelUpTunnel2);
            this.costHeroLevelUpRatios[K_GAME_TUNNEL_3] = Number.parseFloat(ratios.costHeroLevelUpRatio.costHeroLevelUpTunnel3);
            this.costHeroLevelUpRatios[K_GAME_DEFAULT] = Number.parseFloat(ratios.costHeroLevelUpRatio.costHeroLevelUpDefault);

            this.costSupportLevelUpRatios[K_GAME_INTRO] = Number.parseFloat(ratios.costSupportLevelUpRatio.costSupportLevelUpIntro);
            this.costSupportLevelUpRatios[K_GAME_TUNNEL_1] = Number.parseFloat(ratios.costSupportLevelUpRatio.costSupportLevelUpTunnel1);
            this.costSupportLevelUpRatios[K_GAME_TUNNEL_2] = Number.parseFloat(ratios.costSupportLevelUpRatio.costSupportLevelUpTunnel2);
            this.costSupportLevelUpRatios[K_GAME_TUNNEL_3] = Number.parseFloat(ratios.costSupportLevelUpRatio.costSupportLevelUpTunnel3);
            this.costSupportLevelUpRatios[K_GAME_DEFAULT] = Number.parseFloat(ratios.costSupportLevelUpRatio.costSupportLevelUpDefault);


            //}

            this.seriesFirstTermGhost = ratios.seriesFirstTermGhost;
            this.seriesFirstTermBoss = ratios.seriesFirstTermBoss;
            this.seriesFirstTermCostHero = ratios.seriesFirstTermCostHero;
            this.seriesFirstTermDropCoins = ratios.seriesFirstTermDropCoins;

        }
        if (! this.seriesFirstTermGhost) {
            this.seriesFirstTermGhost = 20;
            this.seriesFirstTermCostHero = 1000;
            this.seriesFirstTermBoss = 20;
            //this.seriesFirstTermDropCoins = 1000;
            this.seriesFirstTermDropCoins = 1;
        }
    }

     getSequences(sequenceName) {

        let result = null;
        switch (sequenceName) {
            case 'CostHero':
            case 'DropCoins':
            default:
                result = [
                    {
                        start: 1,
                        max: 100
                    },
                    {
                        start: 101,
                        max: 1000
                    },
                    {
                        start: 1001,
                        max: 3000
                    },
                    {
                        start: 3001,
                        max: 500000
                    }
                ];
        }
        return result;
    }

     getSequenceIntervalInfoFor(level, sequenceName) {
        level = Math.floor(level);
        let seq = this.getSequences(sequenceName);
        let result = null;
        if (!seq) {
            return null;
        }
        seq.forEach((interval, index) => {
            if (level >= interval.start && level <= interval.max) {
                result = interval;
                result.indexInterval = index;
            }
        });

        if (!result) {
            //////console.log(result);
        }

        return result;
    }

     GetSeriesCommonRatio(level, seriesName) {


        let interval = this.getSequenceIntervalInfoFor(level, seriesName);
        let indexInterval = interval ? interval.indexInterval : -1;

        let value = 1;

        let r = -1;

        if (seriesName === 'DropCoins') {
            switch (indexInterval) {
                case K_GAME_INTRO:
                    //value = 1.1;
                    value = this.dropCoinsRatios[K_GAME_INTRO];
                    //value = 5.5;
                    //value = 5.5;
                    break;
                case K_GAME_TUNNEL_1:
                    value = this.dropCoinsRatios[K_GAME_TUNNEL_1];
                    //value = 5.5;
                    break;
                case K_GAME_TUNNEL_2:
                    value = this.dropCoinsRatios[K_GAME_TUNNEL_2];
                    break;
                case K_GAME_TUNNEL_3:
                    value = this.dropCoinsRatios[K_GAME_TUNNEL_3];
                    break;
                default:
                    value = this.dropCoinsRatios[K_GAME_DEFAULT];
            }





        } else if (seriesName === 'CostHero') {

            switch (indexInterval) {
                case K_GAME_INTRO:
                    //value = 1.001;
                    //value = this.costHeroLevelUpRatios[K_GAME_INTRO];
                    value = this.costHeroLevelUpRatios[K_GAME_INTRO];
                    break;
                case K_GAME_TUNNEL_1:
                    //value = 1.25;
                    value = this.costHeroLevelUpRatios[K_GAME_TUNNEL_1];
                    break;
                case K_GAME_TUNNEL_2:
                    //value = 1.327;
                    value = this.costHeroLevelUpRatios[K_GAME_TUNNEL_2];
                    break;
                case K_GAME_TUNNEL_3:
                    //value = 1.327;
                    value = this.costHeroLevelUpRatios[K_GAME_TUNNEL_3];
                    break;
                default:
                    //value = 1.25;
                    value = this.costHeroLevelUpRatios[K_GAME_DEFAULT];
            }

        } else if (seriesName === 'CostSupport') {
            switch (indexInterval) {
                case K_GAME_INTRO:
                    //value = 1.05;
                    value = this.costSupportLevelUpRatios[K_GAME_INTRO];
                    break;
                case K_GAME_TUNNEL_1:
                    //value = 1.1;
                    value = this.costSupportLevelUpRatios[K_GAME_TUNNEL_1];
                    break;
                case K_GAME_TUNNEL_2:
                    //value = 1.15;
                    value = this.costSupportLevelUpRatios[K_GAME_TUNNEL_2];
                    break;
                case K_GAME_TUNNEL_3:
                    //value = 1.18;
                    value = this.costSupportLevelUpRatios[K_GAME_TUNNEL_3];
                    break;
                default:
                    //value = 1.18;
                    value = this.costSupportLevelUpRatios[K_GAME_DEFAULT];
            }


        } else if (seriesName === 'HPBoss') {
            switch (indexInterval) {
                case K_GAME_INTRO:
                    //value = 1.04;
                    value = this.baseHPGhostRatios[K_GAME_INTRO];
                    break;
                case K_GAME_TUNNEL_1:
                    value = this.baseHPGhostRatios[K_GAME_TUNNEL_1];
                    //value = 1.06;
                    break;
                case K_GAME_TUNNEL_2:
                    value = this.baseHPGhostRatios[K_GAME_TUNNEL_2];
                    //value = 1.08;
                    break;
                case K_GAME_TUNNEL_3:
                    value = this.baseHPGhostRatios[K_GAME_TUNNEL_3];
                    //value = 1.12;
                    break;
                default:
                    //value = 1.12;
                    value = this.baseHPGhostRatios[K_GAME_DEFAULT];
            }

        } else if (seriesName === 'HPGhost') {
            switch (indexInterval) {
                case K_GAME_INTRO:
                    //value = 1.02;
                    value = this.baseHPGhostRatios[K_GAME_INTRO];
                    break;
                case K_GAME_TUNNEL_1:
                    //value = 1.03;
                    value = this.baseHPGhostRatios[K_GAME_TUNNEL_1];
                    break;
                case K_GAME_TUNNEL_2:
                    //value = 1.035;
                    value = this.baseHPGhostRatios[K_GAME_TUNNEL_2];
                    break;
                case K_GAME_TUNNEL_3:
                    //value = 1.037;
                    value = this.baseHPGhostRatios[K_GAME_TUNNEL_3];
                    break;
                default:
                    //value = 1.02;
                    value = this.baseHPGhostRatios[K_GAME_DEFAULT];
            }

        } else if (seriesName === 'DMGHero') {
            switch (indexInterval) {
                case K_GAME_INTRO:
                    //value = 1.02;
                    value = this.baseDmgRatios[K_GAME_INTRO];
                    break;
                case K_GAME_TUNNEL_1:
                    //value = 1.018;
                    value = this.baseDmgRatios[K_GAME_TUNNEL_1];
                    break;
                case K_GAME_TUNNEL_2:
                    //value = 1.019;
                    value = this.baseDmgRatios[K_GAME_TUNNEL_2];
                    break;
                case K_GAME_TUNNEL_3:
                    //value = 1.0195;
                    value = this.baseDmgRatios[K_GAME_TUNNEL_3];
                    break;
                default:
                    //value = 1.0195;
                    value = this.baseDmgRatios[K_GAME_DEFAULT];
            }

        } else if (seriesName === 'DMGSupport') {
            switch (indexInterval) {
                case K_GAME_INTRO:
                    //value = 1.02;
                    value = this.supportDmgRatios[K_GAME_INTRO];
                    break;
                case K_GAME_TUNNEL_1:
                    //value = 1.1;
                    value = this.supportDmgRatios[K_GAME_TUNNEL_1];
                    break;
                case K_GAME_TUNNEL_2:
                    //value = 1.035;
                    value = this.supportDmgRatios[K_GAME_TUNNEL_2];
                    break;
                case K_GAME_TUNNEL_3:
                    //value = 1.035;
                    value = this.supportDmgRatios[K_GAME_TUNNEL_3];
                    break;
                default:
                    //value = 1.03;
                    value = this.supportDmgRatios[K_GAME_DEFAULT];
            }

        }
        if (value <= 1) {
            throw 'cant have a ratio of 1, will create pb with logbase for level ' + level + ' ' + seriesName;
        }
        r = new SMPNum(value);
        return r;
    }

     GetTermValueForSeries(level, seriesName) {

        let commonRatio = this.GetSeriesCommonRatio(level, seriesName);
        if (!commonRatio) {
            throw 'cant generate series without ratio'
        }

        let rPowered = SMPNum.pow(commonRatio, level - 1);

        let firstTermOfSequence = new SMPNum(1);
         //let firstTermOfSequence = this.GetFirstTermValueBySeriesName(seriesName);

        return SMPNum.multSmpNum(firstTermOfSequence, rPowered);
    }

     GetFirstTermForSeries(level, seriesName) {
        let defaultFirstTerm = this.GetTermValueForSeries(level, seriesName);

        let modificator = this.GetFirstTermValueBySeriesName(seriesName);
        defaultFirstTerm = SMPNum.multSmpNum(modificator,defaultFirstTerm);

        if (seriesName === 'CostSupport') {
            defaultFirstTerm = SMPNum.divSmpNum(defaultFirstTerm, new SMPNum(30));
        }
        return defaultFirstTerm;
    }

    ComputeSumForWholeSeriesWithInteger(seriesName,level) {
        //S = firstTerm * (1-commonRatio^total_terms / 1-commonRatio)

        //let firstTerm = this.GetFirstTermForSeries(level, seriesName);
        let firstTerm = 1000;
        //let commonRatio = this.GetSeriesCommonRatio(level, seriesName);

        let temp = firstTerm * (  (Math.pow(2,level) -1 )) / (2-1)
        return temp;
    }


    ComputeSumGeometricForSeries(seriesName,level) {
        //S = firstTerm * (1-commonRatio^total_terms / 1-commonRatio)
        //WARNING : on prends pas en compte encore les changements d'interval.


        let commonRatio = this.GetSeriesCommonRatio(level, seriesName);

        let numerator = SMPNum.pow(commonRatio, level);
        numerator = SMPNum.minus(numerator, new SMPNum(1));
        let denominator = SMPNum.minus(commonRatio, new SMPNum(1));

        //let temp = SMPNum.divSmpNum(numerator, denominator);

        let firstTerm = this.GetFirstTermForSeries(1, seriesName);

        let temp = SMPNum.multSmpNum(firstTerm, numerator);

        return SMPNum.divSmpNum(temp,denominator);
        //return SMPNum.multSmpNum(firstTerm, temp);
    }


     ComputeSumForWholeSeries(seriesName, level) {
        //S = firstTerm * (1-commonRatio^total_terms / 1-commonRatio)
         if (level === 1) {
             //return new SMPNum(this.GetFirstTermForSeries(1,seriesName));
             //return new SMPNum(1);
         }

        let gameInterval = this.getSequenceIntervalInfoFor(level, seriesName);
        let commonRatio = this.GetSeriesCommonRatio(level, seriesName);

        if (!gameInterval) {
            //////console.log(level + ' ' + seriesName);
        }

        let numerator = SMPNum.pow(commonRatio, gameInterval.max);
        numerator = SMPNum.minus(numerator, new SMPNum(1));
        let denominator = SMPNum.minus(commonRatio, new SMPNum(1));

        let temp = SMPNum.divSmpNum(numerator, denominator);

        let firstTerm = this.GetTermValueForSeries(level,seriesName);
        //let firstTerm = this.GetTermValueForSeries(1,seriesName);
        //let firstTerm = this.GetFirstTermForSeries(level, seriesName);

        return SMPNum.multSmpNum(firstTerm, temp);
    }

     ComputeRemainingSumForSeries(seriesName, level) {

        let all = this.ComputeSumForWholeSeries(seriesName, level);


        //calculation of the sum to nth , n == level
        let commonRatio = this.GetSeriesCommonRatio(level, seriesName);

        let numerator = SMPNum.pow(commonRatio, level);
        numerator = SMPNum.minus(numerator, new SMPNum(1));
        let denominator = SMPNum.minus(commonRatio, new SMPNum(1));

        let temp = SMPNum.divSmpNum(numerator, denominator);

        //let firstTerm = this.GetTermValueForSeries(1,seriesName);
        let firstTerm = this.GetFirstTermForSeries(1, seriesName);

        let sumToLevel = SMPNum.multSmpNum(firstTerm, temp);
        //end calculation

        return SMPNum.minus(all, sumToLevel);
    }


    ComputeGoldGeneratedByQuestAtLevel(ghostLevel) {
        console.log(ghostLevel);
        return new SMPNum(0);
    }

     CostUnlockSupport(supportIndex) {


        let coefficient = 20;
        if (supportIndex < 5) {
            coefficient = 30;
        } else if (supportIndex < 10) {
            coefficient = 35;
        } else if (supportIndex < 15) {
            coefficient = 400;
        } else {
            coefficient = 500;
        }

        let cost = new SMPNum(1.075);

        let result = SMPNum.pow(cost, supportIndex * coefficient);
        return result;
    }


     GetNumberOfSupportUnlockable(moneyAvailable, lastSupportUnlockedIndex) {

        let cost = this.CostUnlockSupport(lastSupportUnlockedIndex + 1);

        let totalUnlockSupport = 0;

        let everythingUnlocked = false;

        if (lastSupportUnlockedIndex === 17) {
            everythingUnlocked = true
        }

        while (SMPNum.greaterThan(moneyAvailable, cost) && !everythingUnlocked) {
            moneyAvailable = SMPNum.minus(moneyAvailable, cost);
            lastSupportUnlockedIndex = lastSupportUnlockedIndex + 1;
            cost = this.CostUnlockSupport(lastSupportUnlockedIndex + 1);
            totalUnlockSupport = totalUnlockSupport + 1;
            if (lastSupportUnlockedIndex === 17) {
                everythingUnlocked = true
            }
        }
        return totalUnlockSupport;
    }

     CostHero(heroLevel) {

        //let value = 1000;
         let value = this.seriesFirstTermCostHero;
         // if (!value ) {
         //     value = 3;
         // }

        let firstTermOfSequence = new SMPNum(value);
        let termMatchingLevel = this.GetTermValueForSeries(heroLevel, 'CostHero');
        let result = SMPNum.multSmpNum(termMatchingLevel, firstTermOfSequence);
        return result;

    }

    DropCoins(monsterLevel) {

        //let value = 1;
        let value = this.seriesFirstTermDropCoins;
        let firstTermOfSequence = new SMPNum(value);
        let termValue = this.GetTermValueForSeries(monsterLevel, 'DropCoins');
        return SMPNum.multSmpNum(firstTermOfSequence, termValue);

    }

     CostSupport(targetSupportLevel) {
        // Function CostSupport(supportIndex,supportLevel)
        // ''CostSupport = (supportIndex * 50) * (1.075 ^ (supportLevel /50))
        // value = supportIndex * CostHero(supportLevel) / 30
        // CostSupport = value
        // ''CostSupport = 100
        // End Function

        //let cost = this.CostHero(targetSupportLevel)
        //cost =  SMPNum.divSmpNum(cost,new SMPNum(60));

        let firstTermOfSequence = new SMPNum(1);
        let termMatchingLevel = this.GetTermValueForSeries(targetSupportLevel, 'CostSupport');
        let cost = SMPNum.multSmpNum(termMatchingLevel, firstTermOfSequence);

        return cost;
    }

     HPBoss(bossLevel) {
        //let value = 1000;
         let value = this.seriesFirstTermGhost;

        //let interval = this.getSequenceIntervalInfoFor(bossLevel, 'HPBoss');
        //let indexInterval = interval ? interval.indexInterval : -1;

        //switch (indexInterval) {
        //    case K_GAME_INTRO :
        //        value = 20;
        //        break;
        //}


        let firstTermOfSequence = new SMPNum(value);

        let termMatchingLevel = this.GetTermValueForSeries(bossLevel, 'HPBoss');
        let result = SMPNum.multSmpNum(termMatchingLevel, firstTermOfSequence);

        result = SMPNum.multSmpNum(new SMPNum(200),result);


        return result;

    }

    GetFirstTermValueBySeriesName (seriesName) {
        let value = 1;
        switch(seriesName){
            case 'HPGhost' : {
                value = this.seriesFirstTermGhost;
                break;
            }
            case 'HPBoss' : {
                value = this.seriesFirstTermBoss;
                break;
            }
            case 'CostHero' : {
                value = this.seriesFirstTermCostHero;
                break;
            }
            case 'DropCoins' : {
                value = this.seriesFirstTermDropCoins;
                break;
            }
            default:
                value = 1;
        }
        return new SMPNum(value);
    }


     HPGhost(monsterLevel) {

        //let value = 1000;
         let value = this.seriesFirstTermGhost;

        // let interval = this.getSequenceIntervalInfoFor(monsterLevel, 'HPGhost');
        // let indexInterval = interval ? interval.indexInterval : -1;
        //
        // switch (indexInterval) {
        //     case K_GAME_INTRO :
        //         value = 20;
        //         break;
        // }


        let firstTermOfSequence = new SMPNum(value);

        let termMatchingLevel = this.GetTermValueForSeries(monsterLevel, 'HPGhost');
        let result = SMPNum.multSmpNum(termMatchingLevel, firstTermOfSequence);


        return result;
    }

     DMGHero(heroLevel) {

        let value = 1;
        let firstTermOfSequence = new SMPNum(value);

        let termMatchingLevel = this.GetTermValueForSeries(heroLevel, 'DMGHero');
        let result = SMPNum.multSmpNum(termMatchingLevel, firstTermOfSequence);


        return result;
    }


     DMGStandardSupport(supportLevel) {
        let value = 1;
        let firstTermOfSequence = new SMPNum(value);

        let termMatchingLevel = this.GetTermValueForSeries(supportLevel, 'DMGSupport');
        let result = SMPNum.multSmpNum(termMatchingLevel, firstTermOfSequence);

        return result;
    }


     GetStrategyFormula(seriesName) {
        if (seriesName === 'DropCoins') {
            return this.DropCoins;
        } else if (seriesName === 'CostSupport') {
            return this.CostSupport;
        } else if (seriesName === 'CostHero') {
            return this.CostHero;
        }
    }

     GetDiamondCostForLevelUpPet(totalPet,totalLevel) {
        let diamandCostRatio = 10;
        let firstLevel = 0;
        let sum = firstLevel + (totalLevel-1)*diamandCostRatio;
        if (totalLevel === 1) {
            return 0;
        }
        sum = sum * totalPet;
        return sum;
    }



     GetMaxLevelThatCanBuyWithExistingGold(_gold, _currentLv, sequenceName) {

          let level = 0;
          let sequenceIntervals = this.getSequences(sequenceName);
          for (let i = 0; i < sequenceIntervals.length; i++)
          {
               let interval = sequenceIntervals[i];
               if (interval.max <= _currentLv)
               {
                    continue;
               }

               let cmRatio = this.GetSeriesCommonRatio(i, sequenceName).ToDoubleIfPossible();
               let firstTerm = 6;//this.GetFirstTermForSeries(i, sequenceName);

               let newFirstTerm = firstTerm;
               let newCurrentLv = 0;

               let cost = new SMPNum(0);
               let levelToGet = 0;

               if (i == 0)
               {
                    newCurrentLv = _currentLv;
                    levelToGet = interval.max - _currentLv;
                    cost = this.GeometrySum(levelToGet, firstTerm, cmRatio, _currentLv + 1);
               }
               else
               {
                    if (_currentLv >= interval.start && _currentLv <= interval.max)
                    {
                         levelToGet = interval.max - _currentLv;
                         newCurrentLv = _currentLv - sequenceIntervals[i - 1].max;
                    }
                    else
                    {
                         levelToGet = interval.max - sequenceIntervals[i - 1].max;
                    }

                    newFirstTerm = this.GetUnBaseOnLevelAndFirstTerm(interval.start, firstTerm, sequenceName);
                    cost = this.GeometrySum(levelToGet, newFirstTerm, cmRatio, newCurrentLv + 1);
               }


               if (cost < _gold)
               {
                    level += levelToGet;
                    _gold = _gold - cost;

                    //Debug.Log("If cost : =================");
                    //cost.DisplayDetails();
                    //Debug.Log("If cost : =================");
               }
               else
               {
                    let lv = this.GetUnUsingSum(_gold, newFirstTerm, cmRatio, newCurrentLv + 1);
                    level += lv;
                    //Debug.Log("Level : " + level);
                    break;
               }
          }

          return level;
     }

     GeometrySum( n, firstTerm, cmRatio, current = 1) {
          if (current <= 0) {
               throw ("Current must be bigger than zero!");
          }

          let u1 = firstTerm;
          let cmNum = SMPNum.fromNum(cmRatio);
          let u = SMPNum.multSmpNum(SMPNum.pow(cmNum, current - 1), SMPNum.fromNum(u1));
          let op1 = SMPNum.minus(SMPNum.pow(cmNum, n), SMPNum.fromNum(1));
          op1 = SMPNum.divSmpNum(op1, SMPNum.fromNum(cmRatio - 1));
          let sum = SMPNum.multSmpNum(op1, u);

          return sum;
     }

     GetUnBaseOnLevelAndFirstTerm(level, firstTerm, sequenceName) {
          let firstTermOfSequence = firstTerm;//new SMPNum(firstTerm);
          let termMatchingLevel = this.GetTermValueForSeries(level, sequenceName);
          let result = termMatchingLevel * firstTermOfSequence;

          return result;

     }

     GetUnUsingSum(sum, firstTerm, cmRatio, current = 1) {
          if (current <= 0)
          {
               throw ("Current must be bigger than zero!");
          }

          //sum - SMPNum
          //firstTerm - Number
          //cmRatio - number


          let u1 = SMPNum.fromNum(firstTerm);

          let u = SMPNum.multSmpNum(
               u1,
               SMPNum.pow(
                    SMPNum.fromNum(cmRatio),
                    current - 1
               )
          ) ;

          let first = SMPNum.multSmpNum(
               sum ,
               SMPNum.plus(
                    SMPNum.divSmpNum(SMPNum.fromNum(cmRatio - 1) , u) ,
                    SMPNum.fromNum(1)
               )
          );

          let result = SMPNum.logBaseSmpNum(first, cmRatio)
          let level = 0;
          if (result.IsDoubleInifinity())
          {
               level = Number.MAX_VALUE;
          }
          else
          {
               level = result.ToDoubleIfPossible();
          }

          return level;
     }

}


//export default Gameplay
module.exports = Gameplay;
